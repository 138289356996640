import React from 'react';

import { IRenderWidgetProps } from '../../../../assets/modules/renderWidget/model';
import { getWidgetInitialiser } from '../../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../../utils/loadCSS';
import { getInitialiser } from '../../../modules/getInitialiser';
import { GetInitialiserProps, WidgetWrapper } from '../../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../../types/GetJSON';
import { MerchantLinkData } from '../../../types/MerchantLinkData';
import { WidgetInitialiserOptions } from '../../../types/WidgetInitialiser';
import type { WidgetProps } from '../../../types/WidgetProps';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import PriceRetail from '../Retail/PriceRetail';
import PriceSubscriptions from '../Subscriptions/PriceSubscriptions';

loadCSS('price');

const Price: React.FC<WidgetProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetStandard },
  } = props;
  return (
    <HawkWidgetStandard {...props}>
      <Consumer>
        {(value): JSX.Element => {
          const { activeTab, site } = value;

          switch (activeTab?.category) {
            case 'subscriptions':
              return (
                <PriceSubscriptions
                  className={site.type === 'responsive' ? 'leftLayout' : 'leftLayoutProgressive'}
                />
              );
            default:
              return <PriceRetail />;
          }
        }}
      </Consumer>
    </HawkWidgetStandard>
  );
};

Price.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: MerchantLinkData,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default Price;
